import React, { useEffect, useState } from 'react'
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import Topnavbar from '../Components/Top/Topnavbar';
import { useParams } from 'react-router-dom';
import { FaArrowAltCircleUp } from 'react-icons/fa';
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry from 'react-masonry-css'
import { Flex } from '@chakra-ui/react'

function Gallery() {
    const params                                                  = useParams();
    const [processing, setProcessing]                             = useState(false);
    const [galleryTitle, setGalleryTitle]                         = useState("");
    const [activeSearchText, setActiveSearchText]                 = useState();
    const [pagenumber, setPageNumber]                             = useState(0);
    const [imageLimit, setImageLimit]                             = useState(10);
    const [imgListing, setImageListing]                           = useState([]);
    const [hasMore, setHasMore]                                      = useState(true);
    const [showOverLay, setShowOverlay]                           = useState("");
    const [selectedImage, setSelectedImage]                       = useState();

    useEffect(() => {
        declareLocationDetails();
     },[])
 
    useEffect(() => {
      
        if(galleryTitle){
            collectListImageDetails();
        }
     
    },[galleryTitle, pagenumber]);

  
     const declareLocationDetails = async () => { 
         
         setActiveSearchText(params.id);     
         setPageNumber(1);
         try{
 
             const repTitle = await axios.get(CONSTANTS.API_URLG + "?titlesingle="+ params.id);          
             setGalleryTitle(repTitle.data[0].event_title);
         }catch(error){
             console.log(error);
         }
     }

     const collectListImageDetails = async () => {
        try{
            console.log("#" + pagenumber);

            const repGal = await axios.get(CONSTANTS.API_URLG + "?gallery="+ activeSearchText + "&page=" + pagenumber + "&limit=" + imageLimit);

            console.log(" ((( repGal ))) ");
            console.log(repGal);

            if(repGal.data.content.length > 0){
                ////////////////////////////
                // We have a list

               // setPageNumber(repGal.data.next.page);
                 if(pagenumber === 1){
                    setImageListing(repGal.data.content);                  
                    
                }else {
                    setImageListing(prevList => [...prevList, ...repGal.data.content]);
                }

                
            }
           
        }catch(err){
          console.log(err)
        }
     }
     
    const Loader = () => (
        <div className="img-source">
           <img src={CONSTANTS.SMALL_PROCESSING} className="proc" />
        </div>
    )

/////////////////////////////

    const breakpointColumnsObj = {
        default: 4,
        1400: 3,
        900: 2,
        700: 1,
    }
    
        
    const columnClassName = 'my-masonry-grid_column'

    const gutterSpace = '30px'

    const masonryStyles = {
        ml: `-${gutterSpace}`,
    }

    const selector = `& .${columnClassName}`

    masonryStyles[selector] = {
        pl: gutterSpace,
        backgroundClip: 'padding-box',
    }

    const currentViewImage = (picture) => {
        console.log(picture);
        setShowOverlay("open");
        setSelectedImage(picture.full == "1" ? picture.path_url : CONSTANTS.DOM_URL + picture.path_url);
    }
    const undoCurrenView = () => {
        setShowOverlay("");
        setSelectedImage(null);
    }
  return (
    <div className="main-display">
        <Topnavbar />
        <div className={"modal-overlay " + showOverLay}>
            <div className="full-lay model-image">
                <img src={selectedImage} className="full-image" />
                <button className="btn btnCloser" onClick={() => undoCurrenView()}><FaArrowAltCircleUp /></button>
            </div>
        </div>
        <div className="phase-section">
            <div className="container">
               <h4>Gallery { galleryTitle && <span>- {galleryTitle}</span>}</h4>
            </div>
            <div className="fill-images">
               <div className="row">
               {
                    (imgListing.length > 0) && (
                        <InfiniteScroll
                        next={() => setPageNumber( pagenumber + 1)}
                        dataLength={imgListing.length}
                        hasMore={hasMore}
                        loading={Loader}
                    >
                        <Flex 
                            className={columnClassName} 
                            as={Masonry}
                            breakpointCols={breakpointColumnsObj}
                            sx={masonryStyles}
                            mt='2rem'
                            >
                            {
                                imgListing.map((pictureItem, key) => {
                                        return <div className="pic-box" key={key} onClick={() => currentViewImage(pictureItem)}
                                                  >
                                                    <img 
                                                      className="img-special"
                                                       src={pictureItem.full == "1" ? pictureItem.path_url : CONSTANTS.DOM_URL + pictureItem.path_url}
                                                       loading="lazy" 
                                                       alt="TLC Conference"
                                                      />
                                                     
                                                </div>
                                            })
                            }
                        </Flex>
                    </InfiniteScroll>

                    )
                 }
               </div>
             
            </div>
        </div>
        
    </div>
  )
}

export default Gallery