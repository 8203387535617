import { useRef, useState } from "react";
import Topnavbar from "../Components/Top/Topnavbar"
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { toast } from 'react-toastify';

function Uploader() {

    const [isLoadingSimple, setIsLoadingSimple]                   = useState(false);
    const [urlList, setUrlList]                                   = useState([])
    const [countIncrease, setCountIncrease]                       = useState(0);

    const [checked, setChecked]                                   = useState(true);

    const handleChange = () => {
      setChecked(!checked);
    };

    const titleNameRef                                            = useRef();

    const fileDataRef                                             = useRef();

    const handleTheGalleryTitle = async (e) => {
        e.preventDefault();

        try{
            setIsLoadingSimple(true);

            console.log(CONSTANTS.API_URLG);   
            // console.log(fileDataRef.current.files);
            console.log(fileDataRef.current.files.length);
            if(fileDataRef.current.files[0]){
              
                console.log(fileDataRef.current.files);
                
                const arrGallery = [];
                const countFiles = fileDataRef.current.files.length;
              //  console.log(CONSTANTS.UPLOAD_GALLERY);
                const checkerFull = checked ? 1 : 0;
                const eventCode   = Math.floor(Math.random() * 1000000000);

                for (let i = 0; i < countFiles; i++) {
                    const formData = new FormData();
                    formData.append('exibitionurl',  fileDataRef.current.files[i]);
                    formData.append('fullcheck', checkerFull);
                    formData.append('eventtitle', titleNameRef.current.value);
                    formData.append('eventcode', eventCode);

                    const response = await axios.post(CONSTANTS.UPLOAD_GALLERY, formData);
                    arrGallery.push({"img_url" : response.data.imageUrl});
                    console.log("_+++++++++++++_");
                    console.log(response.data.imageUrl);                
                   
                    if(i === 0){
                        setUrlList([response.data.imageUrl]);
                    }else {                      
                        setUrlList(oldArray => [...oldArray, response.data.imageUrl] );
                    }
                    setCountIncrease(prev => prev + 1);
                }

                console.log("_=+>   ######################    <+=_");
                console.log("_=+>   ######################    <+=_");
                //console.log(arrGallery);
                const d = new Date();

                const obImages = {
                    "event_title" : titleNameRef.current.value,
                    "year" : d.getFullYear(),
                    "full" : checked ? 1 : 0,
                    "event_code": Math.floor(Math.random() * 1000000000),
                    "list" : arrGallery
                }

                //const response = await axios.get(CONSTANTS.API_URLG + "&eventgallery=" + titleNameRef.current.value + "&");
                console.log(obImages);
                toast.success("Successfully Created.")
                ///////////////////////
            }
            setIsLoadingSimple(false);
        }catch(err){
            console.log(err)
            setIsLoadingSimple(false);
        }
    }
    return (
        <div className="main-display">
           <Topnavbar />
           <div className="phase-section">
                <div className="container">
                <h4>Upload Gallery</h4>
                  <div className="countBox">
                    {countIncrease}
                  </div>
                   <form encType="multipart/form-data">
                            <div className="form-group frg">
                                <div className="text-form">Title: </div>
                                <input 
                                    type="text" 
                                    className="form-control ct-content wide100"   
                                    ref={titleNameRef}    
                                    placeholder="Enter event title"                                   
                                    required/>
                            </div>
                            
                            <div className="form-group frg">
                                <div className="">
                                  <input 
                                     type="checkbox" 
                                     checked={checked}
                                     onChange={handleChange}
                                     />
                                  <span className="mgleft10">Full URL</span>
                                </div>
                            </div>
                            <div className="form-group frg">
                                <div className="text-form">Upload Images: </div>
                                <input 
                                    type="file" 
                                    className="form-control ct-content wide100" 
                                    ref={fileDataRef}
                                    multiple 
                                    required/>
                            </div>
                    
                            <div className="form-group frg">                     
                                <button className="btn btn-orange mgrite" onClick={handleTheGalleryTitle} disabled={isLoadingSimple}>Create Gallery + </button>
                            </div>
                        </form>
                    
                    <div className="row pd10">
                        {
                            (urlList.length > 0) && (
                                urlList.map((linkUrl, index) => {
                                    return (<div className="col-md-1" key={index}>
                                      <div className="bs-image">
                                         <img src={linkUrl} className="img-scripter" />
                                      </div>
                                    </div>)
                                })
                            )
                        }
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Uploader