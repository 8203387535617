export const API_URL          = "https://mevent.co.za/incl/gallery.api.v1.php";
export const API_URLG         = "https://gallery.mevent.co.za/apiphp/process.php"
//export const API_URL         = "https://demo.mevent.co.za/incl/gallery.api.v1.php";
export const DOM_URL          = "https://mevent.co.za/incl/";
export const ALL_TEST         = "?all=1";

export const UPLOAD_GALLERY   = "https://mevent.co.za/incl/gallery.api.v2.php";

export const SESSION_COOKIE   = "USER_php_lgallary_confrence";
export const SMALL_PROCESSING = "https://api.picnick.co.za/assets/smallprocess.gif";

export const PREVIEW_IMAGE    = "https://cjmarketing.co/image_directory/preview.png";

