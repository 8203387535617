import { useEffect, useState } from "react"
import Topnavbar from "../Components/Top/Topnavbar"
import * as CONSTANTS from "../CONSTANTS";
import axios from "axios";
import { Link } from "react-router-dom";

function Home() {

    const [navigateItems, setNavigateItems]                  = useState([]);
    const [activeSearchText, setActiveSearchText]            = useState();
    

    useEffect(() => {
        collectNavObjects();
    },[])

    const collectNavObjects = async() => {
        try{
         // console.log(CONSTANTS.API_URLG + "?corridor=1");
          const respCorridor = await axios.get(CONSTANTS.API_URLG + "?corridor=1");
              console.log(respCorridor.data);
              setNavigateItems(respCorridor.data);
              //console.log(respCorridor.data[0].event_code);
              
        }catch(err){
          console.log(err);
        }
    }

    return (
        <div className="main-display">
            <Topnavbar />
            <div className="phase-section">
                <div className="container">
                    <div className="row">
                    {
                        navigateItems && (
                            navigateItems.map((navi, index ) => {
                                return (
                                <div className="col-md-3 base-hitting" key={index}>
                                       <Link to={"/gallery/"+ navi.code}
                                            state={navi}
                                            className="no-line">    

                                       <div className="title-blocks-items" style={{ 
                                            backgroundImage: `url(${navi.full == "1" ? navi.path_url : CONSTANTS.DOM_URL + navi.path_url})` 
                                        }}>
                                           <div className="sahara-base">
                                             <div className="inner-grad-layer">
                                               <div className="label-title">
                                                  {navi.even_title} 
                                                </div>
                                                <div className="label-year">
                                                  {navi.event_year}
                                                </div>
                                             </div>
                                           </div>
                                        </div>
                                       </Link>
                                </div>)
                            })
                        )
                    }
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Home