import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Home from "./Pages/Home";
import Uploader from "./Pages/Uploader";
import Gallery from "./Pages/Gallery";

function App() {
  return (
    <>
    <Router>
       <div className="main-container">
         <Routes>
            <Route path="/" element={<Home/>}/> 
            <Route path="/upload" element={<Uploader/>}/>
            <Route path="/gallery/:id" element={<Gallery />}/>
         </Routes>
       </div>
    </Router>
    <ToastContainer />
   </>
  );
}

export default App;
