import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as CONSTANTS from "../../CONSTANTS";
import axios from "axios";
import './topnavbar.css';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout, reset } from '../../reduxAuth/authSlice';
import HeaderBack from '../../assets/mevent_gallery_header.png';
import HeaderLogo from '../../assets/tlc_conference.png';
import { toast } from 'react-toastify';

function Topnavbar() {

    const navigate                                      = useNavigate();
    const dispatch                                       = useDispatch();

    const {user, isLoading, isError, isSuccess, message} = useSelector((state) => state.auth);
  
    const [showLoginConf, setShowLoginConf]             = useState(false);
    const [isLoadingSimple, setIsLoadingSimple]         = useState(false);

    const onLogOut = () => {
      dispatch(logout());
      dispatch(reset());
    }


    const phoneNumberRef                                = useRef();
    const passwordRef                                   = useRef();

    
    useEffect(() => {
        
        if(isError){
            toast.error(message)
        }
      
        if(isSuccess || user){
         
            if(isSuccess){
                toast.success("You successfully logged in.");
            }
       
         setShowLoginConf(false);
        }

          dispatch(reset())
    },[user, isError, isSuccess, message, navigate, dispatch])

    const handleTheLogin = async (e) => {
        e.preventDefault();

        try{
            setIsLoadingSimple(true);

            console.log(CONSTANTS.API_URLG);
            const phonenumber = phoneNumberRef.current.value;
            const pass = passwordRef.current.value;
            const userLogin = {
                "phonenumber": phonenumber,
                "pass":pass
            }

            dispatch(login(userLogin));
            
            setIsLoadingSimple(false);
        }catch(err){
            console.log(err)
            setIsLoadingSimple(false);
        }
    }

    return (
      <div className="nav-bar"  style={{ 
            backgroundImage: `url(${HeaderBack})` 
        }}>
          <div className="content-nav">
              <Link to="/" className="no-style">
                <img src={HeaderLogo} className="cont-logo"  />
              </Link>              
               <div className="right-box-nav">
               
                {
                    user ? 
                        <div className="strip-bann">
                            <div className="stitle">
                                <span className="nm-labelings names1">{user.name}</span>  
                                <span className="nm-labelings names2">{user.surname} </span>
                            </div>
                            <Link to="/upload" className="btn btn-orange mgrite">Upload</Link>
                            <button className="btn btn-orange" onClick={onLogOut}>
                                Logout
                            </button>  
                        </div>
                    
                    : 

                    <button className="btn btn-orange" onClick={() => setShowLoginConf(true)}>
                        Login
                    </button>  
                }
                </div>            
          </div>
          <div className="container sp-conte-work">
            <p>Browse through photos of The Local Choice Conferences. This gallery displays pictures from the annual conference, giving you the opportunity to relive the memories of the event.</p>
            <p>Whether you're looking for photos of yourself, the exhibitors, or the most memorable moments from the event, you'll find it all in the The Local Choice Conference Gallery.</p>
            <Link to="/" className="btn btn-orange mgrite">Home</Link>
          </div>
        
          {
            showLoginConf && (
              <div className="modal-narative">
                <div className="modal-diagram">
                      <div className="modal-header">
                        <h4>Login Now </h4>
                        <button className="btn xClose" onClick={() => setShowLoginConf(false)}>X</button>
                      </div>
                      <div className="modal-body">
                         <form encType="multipart/form-data">
                            <div className="form-group frg">
                                <div className="text-form">Phone Number: </div>
                                <input 
                                    type="text" 
                                    className="form-control ct-content wide100"   
                                    ref={phoneNumberRef}    
                                    placeholder="Enter your phone number"                                   
                                    required/>
                            </div>
                            <div className="form-group frg">
                                <div className="text-form">Password: </div>
                                <input type="password" className="form-control ct-content wide100" 
                                    ref={passwordRef} 
                                    placeholder="Enter Your Password"
                                    required/>
                            </div>
                    
                            <div className="form-group frg">
                               <button className="btn btn-orange btn-full ct" onClick={handleTheLogin} disabled={isLoadingSimple} >
                                   Login
                                </button> 
                            </div>
                        </form>
                      </div>
                    </div>
                </div>
            )
        }
      </div>
    )
  }
  
  export default Topnavbar